import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { H4Style, Body } from '../../util/typography';
import Button from '../Button';
import { externalDotWhite, arrowExternalWhite, arrowRightGreenDark, arrowRightGreenRollover, arrowRightWhite } from '../../util/icons';

const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + 1px), ${rightColor} 100%)`;

const FeaturedResourceContent = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
    keytitle {
        color: ${colors.brandGreen};
    }
    lentitle {
        color: ${colors.mulberry};
    }
    ${respondTo.md`
        flex-direction: row;
    `}
`;

const FRImage = styled.img`
    width: 100px;
    height: fit-content;
    height: intrinsic; //Fix height issue in safari
    max-height: 100px;
`;

const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${colors.charcoal};
    justify-content: center;
`;

const Title = styled.div`
    ${H4Style};
`;

const FRButton = styled(Button)`
    margin-top: 6px;
    position: relative;
    /* img {
        opacity: none;
    } */
    &:after {
        content: '';
        position: absolute;
        right: -32px;
        background-image: url(${externalDotWhite}), url(${arrowExternalWhite}), ${splitGradient(colors.greenDark, colors.greenRollOver)};
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 19px;
        height: 19px;
        background-position: 30% 70%, 60% 40%, left;
        background-size: 3px 3px, 8px 8px, 200% 100%;
    }
    &:hover {
        &:after {
            animation-duration: 0.4s;
            animation-name: external-animation1;
            animation-fill-mode: both;
        }
    }
    
    @keyframes external-animation1 {
        0% {
            background-position: 30% 70%, center 50%, left;
        }

        50% {
            background-position: 30% 70%, calc(100% + 10px) calc(0% - 10px), center;
        }

        50.001% {
            background-position: 30% 70%, calc(0% - 10px) calc(100% + 10px), center;
        }

        100% {
            background-position: 30% 70%, 60% 40%, right;
        }
    }

`;

const FRBody = styled(Body)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const FeaturedResource = ({children, imgSrc, imgAlt, eyebrow, buttonText, buttonIcon = 'external', buttonTo, noTitle, buttonTarget = '_blank'}) => {
    return (
        <FeaturedResourceContent>
            <FRImage src={imgSrc} alt={imgAlt} />
            <TextArea>
                {eyebrow &&<Body>{eyebrow}</Body>}
                {children && !Array.isArray(children) && !noTitle ? <Title>{children}</Title> : children && Array.isArray(children) && <Title>{children[0]}</Title>}
                {children && children.length > 1 ? <FRBody>{children.slice(1)}</FRBody> : noTitle ? <FRBody>{children}</FRBody> : <></>}
                {buttonText && <FRButton type='tertiary-dark' target={buttonTarget} noIcon to={buttonTo}>{buttonText}</FRButton>}
            </TextArea>
        </FeaturedResourceContent>
)};
export default FeaturedResource;